.navbar_center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid #d0d0d05a;
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 200;
    margin-bottom: 75px;
}

.navbar_center a {
    text-decoration: none;
    color: #787878;
    font-size: 13px;
    margin: 10px 0px;
    letter-spacing: 3px;
}

.navbar_center a:hover {
    color: #e5cda5;
}

.navbar_small_top_div {
    position: fixed;
    left: 0px;
    width: 100%;
    display: none;
    background-color: #262626;
}

.navbar_small_top_header_div {
    padding: 15px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar_small_img {
    width: 120px;
}

#navbar_small_page_list {
    display: none;
}

#navbar_small_menu {
    cursor: pointer;
    transition: .3s;
}

#navbar_small_cancel {
    cursor: pointer;
    display: none;
    transition: .3s;
}

.navbar_small_divider {
    border-bottom: 1px solid #464646;
    width: 100%;
}

.navbar_small_page_list_inner_fixed_div {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #262626;
}

.navbar_small_page_list_inner_div {
    margin: auto;
    margin-top: 50px;
    max-width: 700px;
    width: 100%;
}

.navbar_small_page_list_inner_quote {
    margin-bottom: 30px;
}

.navbar_small_page_list_inner_quote p {
    font-size: 16px;
}

.navbar_small_page_list_inner_links_div {
    border-top: 1px solid #e0e0e0;
}

#navbar_small_page_list_inner_techxact {
    border-bottom: 1px solid #e0e0e0;
}

.navbar_small_page_list_inner_links_div a {
    text-decoration: none;
    color: #9c9c9c;
    font-size: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.navbar_small_page_list_inner_links_div a:hover {
    color: #e0e0e0;
}

@media screen and (max-width: 1280px) {
    .navbar_small_top_div {
        display: inherit;
    }

    .navbar_center {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .navbar_small_top_header_div {
        padding: 15px 0px;
        width: 700px;
        margin: auto;
    }
}

@media screen and (max-width: 800px) {
    .navbar_small_top_header_div {
        width: 90%;
    }

    .navbar_small_page_list_inner_div {
        width: 90%;
    }
}