.App {
  font-family: 'Times New Roman', Times, serif;
  display: flex;
}

.Body {
  width: 100%;
}

.Body_Content {
  width: 800px;
  margin: auto;
}

.Right_Side {
  width: 480px;
  height: 100%;
  position: static;
}

@media screen and (max-width: 1280px) {
  .App {
    flex-direction: column;
  }

  .Right_Side {
    width: 100%;
  }

  .Body_Pages_div {
    padding-top: 100px;
  }
}

@media screen and (max-width: 900px) {
  .Body_Content {
    width: 700px;
  }
}

@media screen and (max-width: 800px) {
  .Body_Content {
    width: 90%;
  }
}